import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Stack } from "@mui/material";

import CustomModal from "../shared/modal_utils/CustomModal";
import "./styles.css";
import { CustomButton } from "../../elements/StyledElements";
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import LabelEditModal from "../../elements/LabelEditModal";
import { API_URL_DEFECTIVEDEVICELABES } from "../../settings";
import axios from "axios";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../elements/utils";
import ConfirmationModal from "../shared/modal_utils/ConfirmationModal";
import Label from "../../elements/Label";
import { Container } from "reactstrap";

const emptyDefectLabel = {
  name: null,
  text: null,
  text_color: "#FFFFFF",
  label_color: "#000000",
  description: null,
};

export default function DefectLabelModal({
  defectLabel,
  setDefectLabel,
  resetDefectLabel,
  session,
}) {
  const [labelForm, setLabelForm] = useState(emptyDefectLabel);
  const [loading, setLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("Neues Label");

  const deleteLabel = async (id) => {
    setLoading(true);

    try {
      await axios.delete(API_URL_DEFECTIVEDEVICELABES + id);
      await resetDefectLabel();
    } catch (error) {
      toast.error(getErrorMessage(error));
      console.error(error);
    }

    setLoading(false);
  };

  const getOpenButton = (toggle) => {
    return <CustomButton onClick={toggle}>Labeleinstellungen</CustomButton>;
  };

  return (
    <>
      <CustomModal
        title="Labeleinstellungen"
        getOpenButton={getOpenButton}
        size="xl"
      >
        <Container style={{ paddingTop: 16 }}>
          <Stack direction="column" spacing={1}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ textAlign: "center" }}>
                      Vorschau
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>Name</TableCell>
                    <TableCell style={{ textAlign: "center" }}>Label</TableCell>
                    <TableCell style={{ textAlign: "left" }}>
                      Beschreibung
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {defectLabel && defectLabel.length > 0 && !loading ? (
                    defectLabel.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell
                          style={{ textAlign: "center", padding: "10px" }}
                        >
                          <Label
                            labelColor={item.label_color}
                            text={item.text}
                            textColor={item.text_color}
                            textSize={12}
                            minWidth="4em"
                          />
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "center", padding: "10px" }}
                        >
                          {item.name}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "center", padding: "10px" }}
                        >
                          {item.text}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", padding: "10px" }}
                        >
                          {item.description}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "center", padding: "10px" }}
                        >
                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="flex-end"
                          >
                            <CustomButton
                              onClick={() => {
                                setLabelForm(item);
                                setIsOpenModal(true);
                                setModalTitle(
                                  `Bearbeiten Sie die Bezeichnung ${item.name}`,
                                );
                              }}
                            >
                              Bearbeiten
                            </CustomButton>
                            <ConfirmationModal
                              title={
                                "Soll die Bezeichnung '" +
                                item.name +
                                "' wirklich entfernt werden?"
                              }
                              confirm={() => deleteLabel(item.id)}
                              getOpenButton={(toggle) => (
                                <CustomButton
                                  onClick={toggle}
                                  color="red"
                                  icon="delete"
                                  iconClassName="IconDeleteButton"
                                >
                                  Löschen
                                </CustomButton>
                              )}
                            />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : !loading ? (
                    <TableRow>
                      <TableCell colSpan={5} style={{ textAlign: "center" }}>
                        Keine Daten
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} style={{ textAlign: "center" }}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
          <CustomButton
            icon="add"
            onClick={() => {
              setLabelForm(emptyDefectLabel);
              setIsOpenModal(true);
              setModalTitle("Neues Label");
            }}
            style={{ float: "right", marginTop: 16 }}
          >
            Neues Label
          </CustomButton>
        </Container>
      </CustomModal>
      <LabelEditModal
        resetParent={resetDefectLabel}
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        form={labelForm}
        setForm={setLabelForm}
        setLabels={setDefectLabel}
        title={modalTitle}
        url={API_URL_DEFECTIVEDEVICELABES}
      />
    </>
  );
}

DefectLabelModal.propTypes = {
  defectLabel: PropTypes.arrayOf(PropTypes.object),
  setDefectLabel: PropTypes.func,
  resetDefectLabel: PropTypes.func,
  session: PropTypes.object,
};
