import React, { Fragment } from "react";
import PropTypes from "prop-types";

import TabView from "../elements/TabView";
import CommissionOverview from "./commissions/CommissionOverview";
import {
  DAPI_URL_CONSTRUCTIONCOMMISSION,
  DAPI_URL_MONTHLYCONSTRUCTIONCOMMISSION,
  DAPI_URL_MONTHLYSALESCOMMISSION,
  DAPI_URL_SALESCOMMISSION,
} from "../settings";
import { hasPermission } from "../elements/utils";
import CommissionSettings from "./commissions/CommissionSettings";

const SALES_GROUPS = [
  "salesmen",
  "telemarketers",
  "sales_managers",
  "admins",
  "managers",
  "office",
];
const CONSTRUCTION_GROUPS = [
  "installers",
  "installer_students",
  "electricians",
  "construction_managers",
  "heating_installers",
  "heating_installer_construction_manager",
];

export default function Commissions({ session }) {
  return (
    <Fragment>
      <TabView
        components={[
          ...(hasPermission(session.user, "commissions_sales") ||
          SALES_GROUPS.includes(session.user.group_key)
            ? [
                {
                  title: "Verkäufe",
                  getChild: () => (
                    <CommissionOverview
                      permission={"commissions_sales"}
                      commissionUrl={DAPI_URL_SALESCOMMISSION}
                      monthlyCommissionUrl={DAPI_URL_MONTHLYSALESCOMMISSION}
                      title="Verkäufe"
                      showExportButton={true}
                      session={session}
                    />
                  ),
                },
              ]
            : []),
          ...(hasPermission(session.user, "commissions_constructions") ||
          CONSTRUCTION_GROUPS.includes(session.user.group_key)
            ? [
                {
                  title: "Baustellen",
                  getChild: () => (
                    <CommissionOverview
                      permission={"commissions_constructions"}
                      commissionUrl={DAPI_URL_CONSTRUCTIONCOMMISSION}
                      monthlyCommissionUrl={
                        DAPI_URL_MONTHLYCONSTRUCTIONCOMMISSION
                      }
                      title="Baustellen"
                      showExportButton={false}
                      session={session}
                    />
                  ),
                },
              ]
            : []),
          ...(hasPermission(session.user, "commissions_settings")
            ? [
                {
                  title: "Einstellungen",
                  getChild: () => <CommissionSettings session={session} />,
                },
              ]
            : []),
        ]}
      />
    </Fragment>
  );
}

Commissions.propTypes = {
  session: PropTypes.object,
};
