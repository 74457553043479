import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container, Col } from "reactstrap";
import DefectiveDeviceModal from "./defective/DefectiveDeviceModal";
import {
  API_URL_DEFECTIVEDEVICE,
  API_URL_DEFECTIVEDEVICELABES,
  API_URL_DEFECTIVEDEVICEMANUFACTURER,
  API_URL_DEFECTIVEDEVICETYPE,
} from "../settings";
import axios from "axios";
import { defaultIfEmpty, hexToRGB, sortById } from "../elements/utils";
import { Table } from "react-bootstrap";
import { Typography, Chip, Stack, Checkbox, IconButton } from "@mui/material";
import LoadingPage from "../elements/LoadingPage";
import ConfirmationModal from "./shared/modal_utils/ConfirmationModal";
import Collapsible from "../elements/Collapsible";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { isMobileOnly } from "react-device-detect";
import MultiSelect from "../elements/MultiSelect";
import DropDown from "../elements/DropDown";
import { CustomButton } from "../elements/StyledElements";

export default function Defective({ session }) {
  const [defective, setDefective] = useState([]);
  const [defectiveLabels, setDefectiveLabels] = useState([]);
  const [defectiveManufacturer, setDefectiveManufacturer] = useState([]);
  const [defectiveType, setDefectiveType] = useState([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedLabel, setSelectedLabel] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [showColumns, setShowColumns] = useState([
    { name: "Bezeichung", show: true },
    { name: "Hersteller", show: true },
    { name: "Seriennummer", show: true },
    { name: "Typ", show: true },
    { name: "Zulieferer", show: true },
    { name: "Grund des Defekts", show: true },
    { name: "Label", show: true },
    { name: "Aktion", show: true },
  ]);
  const [isFilterActive, setIsFilterActive] = useState(false);

  useEffect(() => {
    getDefectiveDevices();
    getDefectiveDevicesLabels();
    getDefectiveDevicesManufacturer();
    getDefectiveDevicesType();
    columnIfMobile();
  }, []);

  useEffect(() => {
    checkIfFilterActive();
  }, [selectedManufacturer, selectedType, selectedLabel]);

  const resetState = () => {
    getDefectiveDevices();
    getDefectiveDevicesLabels();
    getDefectiveDevicesManufacturer();
    getDefectiveDevicesType();
  };

  const columnIfMobile = () => {
    if (isMobileOnly) {
      setShowColumns([
        { name: "Bezeichung", show: true },
        { name: "Hersteller", show: false },
        { name: "Seriennummer", show: false },
        { name: "Typ", show: false },
        { name: "Zulieferer", show: false },
        { name: "Grund des Defekts", show: false },
        { name: "Label", show: false },
        { name: "Aktion", show: true },
      ]);
    }
  };

  const getDefectiveDevices = () => {
    axios
      .get(API_URL_DEFECTIVEDEVICE)
      .then((res) => {
        setDefective(sortById(res.data));
      })
      .then(() => setLoaded(true));
  };

  const getDefectiveDevicesLabels = () => {
    axios
      .get(API_URL_DEFECTIVEDEVICELABES)
      .then((res) => {
        setDefectiveLabels(sortById(res.data));
      })
      .then(() => setLoaded(true));
  };

  const getDefectiveDevicesManufacturer = () => {
    axios
      .get(API_URL_DEFECTIVEDEVICEMANUFACTURER)
      .then((res) => {
        setDefectiveManufacturer(sortById(res.data));
      })
      .then(() => setLoaded(true));
  };

  const getDefectiveDevicesType = () => {
    axios
      .get(API_URL_DEFECTIVEDEVICETYPE)
      .then((res) => {
        setDefectiveType(sortById(res.data));
      })
      .then(() => setLoaded(true));
  };

  function findNameById(ids, referenceArray) {
    if (!ids) return "";
    const item = referenceArray.find((ref) => ref.id === ids);
    return item ? item.name : "";
  }

  const findColorById = (ids, referenceArray, kind) => {
    if (!ids) return "";
    const item = referenceArray.find((ref) => ref.id === ids);
    if (kind === "text") {
      return item ? item.text_color : "";
    } else {
      return item ? item.label_color : "";
    }
  };

  const toCSVcell = (str) => {
    if (str === null) {
      str = "";
    }
    str = `${str}`;

    str = str.replace(/"/g, '""');

    if (str.includes(",") || str.includes("\n") || /^\s+|\s+$/g.test(str)) {
      str = `"${str}"`;
    }
    return str;
  };

  const filterRows = (rows) => {
    return rows.filter((row) => {
      const manufacturerMatch =
        !selectedManufacturer || row.manufacturer === selectedManufacturer;
      const typeMatch = !selectedType || row.type === selectedType;
      const labelMatch =
        selectedLabel.length === 0 ||
        row.label.some((label) => selectedLabel.includes(label));
      return manufacturerMatch && typeMatch && labelMatch;
    });
  };

  const handleManufacturerChange = (selectedValues) => {
    setSelectedManufacturer(selectedValues);
  };

  const handleTypeChange = (selectedValues) => {
    setSelectedType(selectedValues);
  };

  const handleLabelChange = (selectedValues) => {
    setSelectedLabel(selectedValues || []);
  };

  const handleCSVExport = async () => {
    const filteredDefective = filterRows(defective);
    let strCSV =
      ",Bezeichnung,Hersteller,Seriennummer,Typ,Zulieferer,Grund des Defekts,Label\n";
    for (let i = 0; i < filteredDefective.length; i++) {
      strCSV += "\r\n" + toCSVcell(i + 1);
      strCSV += "," + toCSVcell(filteredDefective[i].name);
      strCSV +=
        "," +
        toCSVcell(
          findNameById(
            filteredDefective[i].manufacturer,
            defectiveManufacturer,
          ),
        );
      strCSV += "," + toCSVcell(filteredDefective[i].serial_id);
      strCSV +=
        "," + toCSVcell(findNameById(filteredDefective[i].type, defectiveType));
      strCSV += "," + toCSVcell(filteredDefective[i].supplier);
      strCSV += "," + toCSVcell(filteredDefective[i].description);
      strCSV +=
        "," +
        toCSVcell(
          filteredDefective[i].label
            .map((label) => findNameById(label, defectiveLabels))
            .join(", "),
        );
    }
    strCSV += "\r\n";
    const csvData = new Blob(["\uFEFF" + strCSV], {
      type: "text/csv;charset=utf-8;",
    });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement("a");
    link.href = csvURL;
    link.download = "Defekt.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSetShowColumns = (idx) => {
    if (!isMobileOnly) {
      const newList = [...showColumns];
      newList[idx].show = !newList[idx].show;
      setShowColumns(newList);
    }
  };

  const checkIfFilterActive = () => {
    if (selectedManufacturer || selectedType || selectedLabel.length > 0) {
      setIsFilterActive(true);
    } else {
      setIsFilterActive(false);
    }
  };

  if (!loaded) return <LoadingPage />;
  return (
    <Fragment>
      <Container>
        <Stack direction={isMobileOnly ? "column" : "row"} mt={2} spacing={2}>
          <DefectiveDeviceModal
            session={session}
            resetParent={resetState}
            style={{ marginLeft: "10px", marginRight: "10px" }}
            defectiveDeviceLabels={defectiveLabels}
            setDefectiveDeviceLabels={setDefectiveLabels}
            defectiveDeviceManufacturer={defectiveManufacturer}
            setDefectiveDeviceManufacturer={setDefectiveManufacturer}
            defectiveDeviceType={defectiveType}
            setDefectiveDeviceType={setDefectiveType}
            title="Neues defektes Gerät erstellen"
          />{" "}
          {isFilterActive && (
            <CustomButton onClick={handleCSVExport}>
              ERGEBNISSE EXPORTIEREN
            </CustomButton>
          )}
        </Stack>
        <Stack direction={isMobileOnly ? "column" : "row"} mt={2} spacing={2}>
          {isMobileOnly ? (
            <Collapsible
              getOpenButton={(toggle) => (
                <IconButton
                  size="medium"
                  disableFocusRipple
                  disableRipple
                  style={{ backgroundColor: "transparent" }}
                  onClick={toggle}
                >
                  <FilterAltOutlinedIcon
                    style={{ color: "#424242" }}
                    fontSize="medium"
                  />
                  <Typography className="secondary-textcolor">
                    Defektes Filtern
                  </Typography>
                </IconButton>
              )}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Stack direction={"column"} spacing={2}>
                <Stack>
                  <Typography className="secondary-textcolor">
                    Hersteller:
                  </Typography>
                  <DropDown
                    onChange={handleManufacturerChange}
                    options={defectiveManufacturer.map((manufacturer) => ({
                      value: manufacturer.id,
                      label: manufacturer.name,
                    }))}
                    value={defaultIfEmpty(selectedManufacturer)}
                    text="Hersteller"
                    search={true}
                    name="manufacturer_fiter"
                  />
                </Stack>
                <Stack style={{ width: "60%" }}>
                  <Typography className="secondary-textcolor">
                    Label:
                  </Typography>
                  <MultiSelect
                    onChange={handleLabelChange}
                    options={defectiveLabels.map((label) => ({
                      value: label.id,
                      label: label.name,
                    }))}
                    values={defaultIfEmpty(selectedLabel)}
                    text="Label"
                    name="label_fiter"
                    search={true}
                  />
                </Stack>
                <Stack>
                  <Typography className="secondary-textcolor">Typ:</Typography>
                  <DropDown
                    onChange={handleTypeChange}
                    options={defectiveType.map((type) => ({
                      value: type.id,
                      label: type.name,
                    }))}
                    value={defaultIfEmpty(selectedType)}
                    text="Typ"
                    search={true}
                    name="type_fiter"
                  />
                </Stack>
              </Stack>
            </Collapsible>
          ) : (
            <Col>
              <Collapsible
                getOpenButton={(toggle) => (
                  <IconButton
                    size="medium"
                    disableFocusRipple
                    disableRipple
                    style={{ backgroundColor: "transparent" }}
                    onClick={toggle}
                  >
                    <FilterAltOutlinedIcon
                      style={{ color: "#424242" }}
                      fontSize="medium"
                    />
                    <Typography className="secondary-textcolor">
                      Defektes Filtern
                    </Typography>
                  </IconButton>
                )}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Stack direction={"row"} spacing={2}>
                  <Stack direction={"column"}>
                    <Typography className="secondary-textcolor">
                      Hersteller:
                    </Typography>
                    <DropDown
                      onChange={handleManufacturerChange}
                      options={defectiveManufacturer.map((manufacturer) => ({
                        value: manufacturer.id,
                        label: manufacturer.name,
                      }))}
                      value={defaultIfEmpty(selectedManufacturer)}
                      text="Hersteller"
                      search={true}
                      name="manufacturer_fiter"
                    />
                    <Typography className="secondary-textcolor">
                      Label:
                    </Typography>
                    <MultiSelect
                      onChange={handleLabelChange}
                      options={defectiveLabels.map((label) => ({
                        value: label.id,
                        label: label.name,
                      }))}
                      values={defaultIfEmpty(selectedLabel)}
                      text="Label"
                      name="label_fiter"
                      search={true}
                    />
                  </Stack>
                  <Stack direction={"column"}>
                    <Typography className="secondary-textcolor">
                      Typ:
                    </Typography>
                    <DropDown
                      onChange={handleTypeChange}
                      options={defectiveType.map((type) => ({
                        value: type.id,
                        label: type.name,
                      }))}
                      value={defaultIfEmpty(selectedType)}
                      text="Typ"
                      search={true}
                      name="type_fiter"
                    />
                  </Stack>
                </Stack>
              </Collapsible>
            </Col>
          )}
          <Col>
            <Collapsible
              getOpenButton={(toggle) => (
                <IconButton
                  size="medium"
                  disableFocusRipple
                  disableRipple
                  style={{ backgroundColor: "transparent" }}
                  onClick={toggle}
                >
                  <FilterAltOutlinedIcon
                    style={{ color: "#424242" }}
                    fontSize="medium"
                  />
                  <Typography className="secondary-textcolor">
                    Tabellenspalten
                  </Typography>
                </IconButton>
              )}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Stack direction={"column"} mt={0} spacing={0}>
                {showColumns.map((showColumn, idx) => {
                  if (idx % 2 === 0) {
                    return (
                      <Stack direction={"row"} mt={0} spacing={0} key={idx}>
                        <Col style={{ display: "flex", alignItems: "center" }}>
                          <Checkbox
                            disableFocusRipple
                            disableRipple
                            checked={showColumns[idx].show}
                            onChange={() => {
                              handleSetShowColumns(idx);
                            }}
                            style={{
                              color: "#424242",
                              backgroundColor: "transparent",
                            }}
                          />
                          <Typography>{showColumns[idx].name}</Typography>
                        </Col>

                        <Col style={{ display: "flex", alignItems: "center" }}>
                          {showColumns[idx + 1] && (
                            <>
                              <td className="align-middle">
                                <Checkbox
                                  disableFocusRipple
                                  disableRipple
                                  checked={showColumns[idx + 1].show}
                                  onChange={() => {
                                    handleSetShowColumns(idx + 1);
                                  }}
                                  style={{
                                    color: "#424242",
                                    backgroundColor: "transparent",
                                  }}
                                />
                              </td>
                              <Typography style={{ alignSelf: "center" }}>
                                {showColumns[idx + 1].name}
                              </Typography>
                            </>
                          )}
                        </Col>
                      </Stack>
                    );
                  }
                  return null;
                })}
              </Stack>
            </Collapsible>
          </Col>
        </Stack>
        <Table>
          <thead>
            <tr>
              {showColumns.map((showColumn) => {
                return showColumn.show ? (
                  <th className="align-middle">
                    <Typography className="secondary-textcolor">
                      {showColumn.name}
                    </Typography>
                  </th>
                ) : null;
              })}
            </tr>
          </thead>
          <tbody>
            {!defective || defective.length <= 0 ? (
              <tr>
                <td colSpan="8" align="center">
                  <Typography className="secondary-textcolor">
                    {" "}
                    Leere Datenbank{" "}
                  </Typography>
                </td>
              </tr>
            ) : (
              filterRows(defective).map((defective) => (
                <tr key={defective.id}>
                  {showColumns[0].show ? (
                    <td
                      className="align-middle"
                      style={{ wordWrap: "break-word" }}
                    >
                      <Typography className="secondary-textcolor">
                        {defective.name}{" "}
                      </Typography>
                    </td>
                  ) : null}
                  {showColumns[1].show ? (
                    <td
                      className="align-middle"
                      style={{ wordWrap: "break-word" }}
                    >
                      <Typography className="secondary-textcolor">
                        {findNameById(
                          defective.manufacturer,
                          defectiveManufacturer,
                        )}{" "}
                      </Typography>
                    </td>
                  ) : null}
                  {showColumns[2].show ? (
                    <td
                      className="align-middle"
                      style={{ wordWrap: "break-word" }}
                    >
                      <Typography className="secondary-textcolor">
                        {defective.serial_id}{" "}
                      </Typography>
                    </td>
                  ) : null}
                  {showColumns[3].show ? (
                    <td
                      className="align-middle"
                      style={{ wordWrap: "break-word" }}
                    >
                      <Typography className="secondary-textcolor">
                        {findNameById(defective.type, defectiveType)}{" "}
                      </Typography>
                    </td>
                  ) : null}
                  {showColumns[4].show ? (
                    <td
                      className="align-middle"
                      style={{ wordWrap: "break-word" }}
                    >
                      <Typography className="secondary-textcolor">
                        {defective.supplier}{" "}
                      </Typography>
                    </td>
                  ) : null}
                  {showColumns[5].show ? (
                    <td
                      className="align-middle"
                      style={{ wordWrap: "break-word" }}
                    >
                      <Typography className="secondary-textcolor">
                        {defective.description}{" "}
                      </Typography>
                    </td>
                  ) : null}
                  {showColumns[6].show ? (
                    <td
                      className="align-middle"
                      style={{ wordWrap: "break-word" }}
                    >
                      {defective.label.map((label, tIdx) => (
                        <Chip
                          key={`label-${tIdx}`}
                          label={findNameById(label, defectiveLabels)}
                          sx={{
                            backgroundColor: hexToRGB(
                              findColorById(label, defectiveLabels, "bg"),
                              1,
                            ),
                            color: hexToRGB(
                              findColorById(label, defectiveLabels, "text"),
                              1,
                            ),
                            margin: "0 4px 4px 0",
                          }}
                          size="small"
                        />
                      ))}
                    </td>
                  ) : null}
                  {showColumns[7].show ? (
                    <td
                      className="align-middle"
                      style={{ wordWrap: "break-word" }}
                    >
                      <Typography
                        className="secondary-textcolor"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <DefectiveDeviceModal
                          session={session}
                          resetParent={resetState}
                          defectiveDeviceLabels={defectiveLabels}
                          setDefectiveDeviceLabels={setDefectiveLabels}
                          defectiveDeviceManufacturer={defectiveManufacturer}
                          setDefectiveDeviceManufacturer={
                            setDefectiveManufacturer
                          }
                          defectiveDeviceType={defectiveType}
                          setDefectiveDeviceType={setDefectiveType}
                          defectiveDevice={defective}
                          title="Defektes Gerät Informationen"
                        />
                        <ConfirmationModal
                          resetParent={resetState}
                          confirm={() =>
                            axios.delete(API_URL_DEFECTIVEDEVICE + defective.id)
                          }
                          title={
                            "Soll '" +
                            defective.name +
                            "' wirklich gelöscht werden?"
                          }
                          style={{ marginRight: "10px" }}
                        />
                      </Typography>
                    </td>
                  ) : null}
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Container>
    </Fragment>
  );
}

Defective.propTypes = {
  session: PropTypes.object.isRequired,
};
