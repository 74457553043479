/* eslint-disable react/prop-types */
import React, { Fragment, useState } from "react";
import CustomModal from "../shared/modal_utils/CustomModal";
import PropTypes from "prop-types";
import { CustomButton } from "../../elements/StyledElements";
import { Stack } from "@mui/material";
import axios from "axios";
import CustomForm from "../../elements/CustomForm";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../elements/utils";

const EditModalFormTemplate = () => [
  {
    type: "text",
    name: "Name",
    key: "name",
  },
];

export default function EditDefectSettingsModal({
  resetParent,
  isOpen,
  setIsOpen,
  form,
  setForm,
  setDefect,
  title,
  url,
}) {
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    showMissingFields: false,
  });

  const template = EditModalFormTemplate();

  const checkIfEmpty = (key, val) => {
    return val === null || val === "";
  };

  const addLabel = async () => {
    setLoadingElements({ ...loadingElements, inProgress: true });

    try {
      const emptyFields = Object.keys(form).filter((key) =>
        checkIfEmpty(key, form[key]),
      );
      if (emptyFields.length > 0) {
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          showMissingFields: true,
        });
        toast.error("Bitte füllen Sie alle Felder aus");
        return;
      }

      setLoadingElements({ inProgress: true, showMissingFields: false });

      const payload = {
        ...form,
        key: form.name.toLowerCase().replace(/ /g, "_"),
      };

      const { data } = await axios.post(url, payload);

      setDefect((labels) => [...labels, data]);
      setIsOpen(false);
    } catch (error) {
      toast.error(getErrorMessage(error));
      console.error(error);
    }

    setLoadingElements({ showMissingFields: false, inProgress: false });
  };

  const editLabel = async () => {
    setLoadingElements({ ...loadingElements, inProgress: true });

    try {
      const emptyFields = Object.keys(form).filter((key) =>
        checkIfEmpty(key, form[key]),
      );
      if (emptyFields.length > 0) {
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          showMissingFields: true,
        });
        toast.error("Bitte füllen Sie alle Felder aus");
        return;
      }

      setLoadingElements({ inProgress: true, showMissingFields: false });

      const { data } = await axios.put(`${url}${form.id}`, form);

      resetParent();
      setDefect((labels) => labels.map((l) => (l.id === data.id ? data : l)));
      setIsOpen(false);
    } catch (error) {
      toast.error(getErrorMessage(error));
      console.error(error);
    }

    setLoadingElements({ showMissingFields: false, inProgress: false });
  };

  const onToggle = () => {
    setLoadingElements({ inProgress: false, showMissingFields: false });
  };

  const getFooter = () => (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      paddingX={2}
    >
      <CustomButton
        color="white"
        onClick={form.key ? editLabel : addLabel}
        disabled={loadingElements.inProgress}
      >
        {loadingElements.inProgress ? "..." : "Speichern"}
      </CustomButton>
    </Stack>
  );

  return (
    <CustomModal
      title={title}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      size="lg"
      getFooter={getFooter}
      onToggle={onToggle}
    >
      <Fragment>
        <CustomForm
          template={template}
          form={form}
          setForm={setForm}
          showMissingFields={loadingElements.showMissingFields}
        />
      </Fragment>
    </CustomModal>
  );
}

EditDefectSettingsModal.propTypes = {
  resetParent: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  form: PropTypes.object,
  setForm: PropTypes.func,
  setDefect: PropTypes.func,
  title: PropTypes.string,
  url: PropTypes.string,
};
