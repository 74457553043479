import React, { useState, useRef, useEffect, Fragment } from "react";
import { PropTypes } from "prop-types";
import { Form, FormGroup, Input } from "reactstrap";
import DropDown from "../../elements/DropDown";
import { Typography } from "@mui/material";
import CustomModal from "../shared/modal_utils/CustomModal";
import AddNewItemForm from "./AddNewItemForm";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";
import TextField from "../../elements/TextField";
import UploadImage from "../shared/images/UploadImage";
import Compress from "browser-image-compression";
import ImageStage from "../shared/images/ImageStage";
import PdfViewModal from "../shared/modal_utils/PdfViewModal";
import { defaultIfEmpty } from "../../elements/utils";
import MultiSelect from "../../elements/MultiSelect";
import {
  API_URL_DEFECTIVEDEVICELABES,
  API_URL_DEFECTIVEDEVICEMANUFACTURER,
  API_URL_DEFECTIVEDEVICETYPE,
} from "../../settings";
import axios from "axios";
import DefectLabelModal from "./DefectLabelModal";
import EditDefectSettings from "./EditDefectSettings";

const emptyNewItemForm = {
  name: null,
  key: null,
};

export default function DefectiveDeviceForm({
  session,
  resetParent,
  defectiveDevice,
  setDefectiveDevice,
  showMissingFields,
  manufacturer,
  setManufacturer,
  types,
  setTypes,
  labels,
  setLabels,
}) {
  const [newItemForm, setNewItemForm] = useState({ ...emptyNewItemForm });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalKind, setModalKind] = useState("");
  const [fileType, setFileType] = useState("");
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });
  const fileName = useRef(null);

  useEffect(() => {
    if (defectiveDevice.additional_file) {
      const fileExtension = defectiveDevice.additional_file.name
        ? defectiveDevice.additional_file.name.split(".").pop()
        : defectiveDevice.additional_file.split(".").pop();
      if (["img", "png", "jpg", "jpeg"].includes(fileExtension)) {
        setFileType("image");
      }
      if (fileExtension === "pdf") {
        setFileType("pdf");
      }
    }
  }, [defectiveDevice.additional_file]);

  const setImage = (image) => {
    setDefectiveDevice((p) => ({ ...p, photo: image }));
  };

  const getFooter = (onToggle) => (
    <SaveModalFooter
      submitError={loadingElements.submitError}
      inProgress={loadingElements.inProgress}
      onSave={() => onSubmit(onToggle)}
    />
  );

  const submit = async (newItemForm) => {
    const key = newItemForm.name.replace(/ /g, "_").toLowerCase();
    newItemForm.key = key;
    if (modalKind === "Label") {
      return axios.post(API_URL_DEFECTIVEDEVICELABES, newItemForm);
    }
    if (modalKind === "Hersteller") {
      return axios.post(API_URL_DEFECTIVEDEVICEMANUFACTURER, newItemForm);
    }
    if (modalKind === "Typ") {
      return axios.post(API_URL_DEFECTIVEDEVICETYPE, newItemForm);
    }
  };

  const onSubmit = async (toggle) => {
    setLoadingElements({ ...loadingElements, inProgress: true });
    return submit(newItemForm)
      .then((res) => {
        resetParent();
        toggle();
        clearData();
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .catch((error) => {
        console.error(error);
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: true,
        });
      });
  };

  const handleDropDownChange = (value, key, name) => {
    if (value === "add-new") {
      handleOpenModal(name);
    } else {
      setDefectiveDevice((p) => ({ ...p, [key]: value }));
    }
  };

  const handleOpenModal = (kind) => {
    setModalKind(kind);
    setIsModalOpen(true);
  };

  const onToggle = (isOpen) => {
    clearData();
    setIsModalOpen(false);
  };

  const clearData = () => {
    setLoadingElements({
      inProgress: false,
      submitError: false,
      showMissingFields: false,
    });
    setNewItemForm({ ...emptyNewItemForm });
  };

  const addNewItemFormModal = () => (
    <CustomModal
      isOpen={isModalOpen}
      setIsOpen={setIsModalOpen}
      title={`${modalKind === "Label" ? "Neues" : "Neuen"} ${modalKind} Hinzufügen`}
      getFooter={getFooter}
      onToggle={(isOpen) => setIsModalOpen(isOpen)}
      onClose={onToggle}
    >
      <AddNewItemForm
        session={session}
        newItemForm={newItemForm}
        setNewItemForm={setNewItemForm}
        showMissingFields={showMissingFields}
        kind={modalKind}
      />
    </CustomModal>
  );

  const onChangeFile = (e) => {
    if (!e.target.files || e.target.files.length < 1) return;
    const file = e.target.files[0];
    fileName.current = file.name.split(".");
    const fileExtension = fileName.current[fileName.current.length - 1];

    if (
      fileExtension === "img" ||
      fileExtension === "png" ||
      fileExtension === "jpg" ||
      fileExtension === "jpeg"
    ) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1600,
        useWebWorker: true,
      };

      Compress(file, options)
        .then((compressedBlob) => {
          compressedBlob.lastModifiedDate = new Date();
          const convertedBlobFile = new File([compressedBlob], file.name, {
            type: file.type,
            lastModified: Date.now(),
          });
          setDefectiveDevice((p) => ({
            ...p,
            additional_file: convertedBlobFile,
          }));
        })
        .catch((e) => {
          alert("Fehler bei der Bildkompression!");
          console.error(e);
        });
      setFileType("image");
    }
    if (fileExtension === "pdf") {
      setDefectiveDevice((p) => ({ ...p, additional_file: file }));
      setFileType("pdf");
    }
  };

  return (
    <Form>
      <FormGroup>
        <Typography>Bezeichnung:</Typography>
        <Input
          id="input"
          type="text"
          name="name"
          value={defectiveDevice.name || ""}
          onChange={(e) =>
            setDefectiveDevice((p) => ({ ...p, name: e.target.value }))
          }
        />
      </FormGroup>
      <FormGroup>
        <Typography>Hersteller:</Typography>
        <DropDown
          onChange={(value) =>
            handleDropDownChange(value, "manufacturer", "Hersteller")
          }
          options={
            manufacturer
              ? [
                  ...manufacturer.map((manufacturer, index) => ({
                    value: manufacturer.id,
                    label: manufacturer.name,
                  })),
                ]
              : []
          }
          value={defectiveDevice.manufacturer}
          text="Hersteller"
          search={true}
        />
        {isModalOpen && addNewItemFormModal("Hersteller")}
      </FormGroup>
      <FormGroup>
        <EditDefectSettings
          defect={manufacturer}
          setDefect={setManufacturer}
          resetDefect={resetParent}
          session={session}
          title={"Herstellereinstellung"}
          kind={"Hersteller"}
          url={API_URL_DEFECTIVEDEVICEMANUFACTURER}
        />
      </FormGroup>
      <FormGroup>
        <Typography>Seriennummer:</Typography>
        <Input
          id="input"
          type="text"
          name="name"
          value={defectiveDevice.serial_id || ""}
          onChange={(e) =>
            setDefectiveDevice((p) => ({ ...p, serial_id: e.target.value }))
          }
        />
      </FormGroup>
      <FormGroup>
        <Typography>Typ:</Typography>
        <DropDown
          onChange={(value) => handleDropDownChange(value, "type", "Typ")}
          options={
            types ? [...types.map((t) => ({ value: t.id, label: t.name }))] : []
          }
          value={defectiveDevice.type}
          text="Typ"
          search={true}
        />
      </FormGroup>
      <FormGroup>
        <EditDefectSettings
          defect={types}
          setDefect={setTypes}
          resetDefect={resetParent}
          session={session}
          title={"Typeneinstellung"}
          kind={"Typ"}
          url={API_URL_DEFECTIVEDEVICETYPE}
        />
      </FormGroup>
      <FormGroup>
        <Typography>Zulieferer:</Typography>
        <Input
          id="input"
          type="text"
          name="name"
          value={defectiveDevice.supplier || ""}
          onChange={(e) =>
            setDefectiveDevice((p) => ({ ...p, supplier: e.target.value }))
          }
        />
      </FormGroup>
      <FormGroup>
        <TextField
          text={defectiveDevice.description}
          setText={(value) =>
            setDefectiveDevice((p) => ({ ...p, description: value }))
          }
          customRows={4}
          description="Grund des Defekts:"
        />
      </FormGroup>
      <FormGroup>
        <Typography>Label:</Typography>
        <MultiSelect
          onChange={(value) => {
            if (value.includes("add-new")) {
              handleOpenModal("Label");
            } else {
              setDefectiveDevice((p) => ({ ...p, label: value }));
            }
          }}
          options={
            labels
              ? [...labels.map((l) => ({ value: l.id, label: l.name }))]
              : []
          }
          values={defaultIfEmpty(defectiveDevice.label)}
          text="Label"
          search={true}
        />
      </FormGroup>
      <FormGroup>
        <DefectLabelModal
          defectLabel={labels}
          setDefectLabel={setLabels}
          resetDefectLabel={resetParent}
          session={session}
        />
      </FormGroup>
      <FormGroup>
        <Typography>Foto hinzufügen (optional):</Typography>
        <UploadImage
          image={defectiveDevice.photo}
          setImage={setImage}
          width={"50%"}
        />
      </FormGroup>
      <FormGroup>
        <Typography>Datei hinzufügen (optional):</Typography>
        <br />
        <Input
          style={{ width: "400px", boxShadow: "none" }}
          id="input"
          type="file"
          onChange={onChangeFile}
        />
        <br />
        {fileType === "image" ? (
          <ImageStage
            image={defectiveDevice.additional_file}
            maxWidth={600}
            maxHeight={450}
          />
        ) : null}
        {fileType === "pdf" ? (
          <Fragment>
            <PdfViewModal
              title={"Datei hinzufügen"}
              filepath={
                defectiveDevice
                  ? defectiveDevice.additional_file
                  : URL.createObjectURL(defectiveDevice.additional_file)
              }
            />
          </Fragment>
        ) : null}
      </FormGroup>
    </Form>
  );
}

DefectiveDeviceForm.propTypes = {
  session: PropTypes.object,
  defectiveDevice: PropTypes.object,
  setDefectiveDevice: PropTypes.func,
  showMissingFields: PropTypes.bool,
  manufacturer: PropTypes.array,
  types: PropTypes.array,
  labels: PropTypes.array,
  resetParent: PropTypes.func,
  setLabels: PropTypes.func,
  setManufacturer: PropTypes.func,
  setTypes: PropTypes.func,
};
